<template>
	<div style="width: 80%;">
		<div class="flexB marginB80">
			<h3>{{this.edit?'编辑':'新增'}}文章</h3>
			<div style="margin-right: 200px;">
				<el-button type="primary marginR10" color="#787878" @click="() => this.$router.go(-1)">取消</el-button>
				<el-button class="marginR10" v-if='pushData.state!="CANCEL_PUBLISHED"' type="primary" color="#112DF2" @click="tempSave">保存草稿</el-button>
				<el-button type="primary marginR10" @click="previewItem(pushData)">预览效果</el-button>
				<el-button type="danger" @click="save">发布</el-button>
			</div>
		</div>
		<div style="margin-left: 100px;">
			<el-form :model="pushData" ref="formBox" :rules="rules" label-width="180px" class="formBox">
				<el-form-item class="formItems" label="文章标题：" prop="title">
					<el-input v-model="pushData.title" style="max-width: 400px" placeholder="请输入文章标题" maxlength="30" show-word-limit ></el-input>
				</el-form-item>
				<el-form-item label="所属分类：" prop="categoryId">
					<el-cascader ref="cascaderAddr" :options="options" :props='props' :show-all-levels="false" placeholder="全部分类" clearable v-model='pushData.categoryId' />
				</el-form-item>
				<el-form-item label="列表图片：" prop="fileListData">
					<el-upload class="uploadLists" name='files' :data="uploadData" :headers="importHeaders" ref="upload" :action="fileUploadUrl" :file-list="fileListData" accept="image/jpg,image/png,image/jpeg" list-type="picture-card" :limit="1" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload" :on-success="(response, file, fileList)=>{return uploadSuccess(response, file, fileList,1)}" :on-remove="(file,fileList)=>{return handleRemove(file,fileList,1)}">
						<el-icon><Plus /></el-icon>
					</el-upload>
					<div class="tips">
						<div>注意：</div>
						<div>1、建议比例：1：1</div>
						<div>2、建议格式：jpg、png、jpeg</div>
						<div>3、建议数量：1张</div>
						<div>4、建议图片大小2M内</div>
					</div>
					<el-dialog v-model="dialogVisible">
					    <img w-full :src="dialogImageUrl" alt="Preview Image" />
					</el-dialog>
				</el-form-item>
				<el-form-item label="关联标签：">
					<div class="flexA">
						<!-- <el-button class="marginR10" type="primary" @click="save"></el-button> -->
						<el-tag class="marginR20 button-new-tag" type="info" size="large" @click="toSelectTag">+ 选择标签</el-tag>
						<el-tag type="primary" class="marginR10" v-for="(item,index) in selectedTags" :key="index" size="large">{{item.name}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item label="文章内容：" style="width:90%;" prop="content">
					<QuillEditor ref='quillRef' v-model:content="pushData.content" style="width:100%" :options="editorOption" contentType="html" />
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="选择标签" width="600" :model-value="dialogVisible1" align-center>
			<template #default>
				<div class="tags marginB20" :class="item.isSelected?'activeTag':''" v-for="(item,index) in tagList" :key="index" @click='getSelected(index,item)'>{{item.name}}</div>
				<div class="flexA">
					<div class="marginR20">新增</div>
					<el-input class="marginR20" v-model="tagName" maxlength="6" placeholder="最多输入6个字" style="width: 200px;"></el-input>
					<el-button type="danger" @click='saveTags'>保存</el-button>
				</div>
			</template >
			<!-- 脚部按钮 -->
			<template #footer>
			  <span class="dialog-footer">
				<el-button @click="dialogVisible1 = false">取 消</el-button>
				<el-button type="primary" @click="saveSelected">确 定</el-button>
			  </span>
			</template>
		</el-dialog>
		<article-preview :dialog-visible.sync='dialogVisiblePre' :article-item='articleItem' :dialogVisiFlag="dialogVisiFlag"></article-preview>
	</div>
</template>

<script>
	import articlePreview from "@/components/PopTable/articlePreview";
	import Validate from '@/utils/rules'
	import {articlePublish,articleGetById,articleRepublish,articleDraft,articleRedraft,fileUploadUrl,userLogin,categoryTreeList,tagList,tagPsCreate} from '@/api'
	import {formatDateOrder,dateFormat,formatDateArtical} from '@/utils/data'
	// 工具栏配置
	const toolbarOptions = [
	    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
	    ["blockquote", "code-block"], // 引用
	    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
	    [{ script: "sub" }, { script: "super" }], // 上标/下标
	    [{ indent: '-1' }, { indent: '+1' }], // 缩进
	    [{ direction: 'rtl' }], // 文本方向
	    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
	    [{ font: [] }], // 字体种类
	    [{ align: [] }], // 对齐方式
	    ['clean'], // 清除文本格式
	    ['link', 'image', 'video'] // 链接、图片、视频
	]
	import {QuillEditor, Quill } from '@vueup/vue-quill'
	import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import quillTool from '@/utils/quillTool'
	Quill.register(quillTool, true)
	Quill.register('modules/ImageExtend', ImageExtend)
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	export default {
		components: {QuillEditor,articlePreview},
		data () {
			const fileListDataCheck = (rule, value, callback) => {
				if(this.fileListData.length<=0){
					callback(new Error('请上传图片'))
				}else {
					callback()
				}
			}
			const validates = (rule, value, callback) => {
				// reg是用于解析富文本标签里的内容，
				const reg = /(?!<(img|video).*?>)<.*?>/g
				// strings 是解析出来的内容，不包含标签
				const strings = value.replace(reg, '')
				// value是空，callback触发不能为空
				if (value === "") {
					callback(new Error("内容不能为空"));
					// 如果解析出来的内容是空或全部是空格，callback触发不能为空
				} else if (strings.match(/^[ ]*$/)) {
					callback(new Error("内容不能为空"));
				} else {
					callback();
				}
			}
			return {
				fileUploadUrl,
				importHeaders:{
					'Authorization':''
				},
				uploadData:{folder:'article/'},
				fileListData:[],
				imgUrl:[],
				dialogImageUrl:'',
				dialogVisible:false,
				dialogVisible1:false,
				dialogVisiblePre:false,
				tagName:'',
				pushData:{
					title:'',
					content:''
				},
				articleItem:{},
				selectedTags:[],
				demoTags:[
					{name:'孕产',id:1},
					{name:'辅食',id:2},
					{name:'母乳喂养',id:3}
				],
				props:{emitPath:false},
				options:[],
				tagList:[],
				tagIds:[],
				edit: false,
				editorOption: {
					theme: 'snow',
					placeholder: '请输入内容',
					modules: {
						ImageExtend: {
							name: 'files', // 参数名
							action: fileUploadUrl, // 服务器地址，如果为空则采用base64插入图片
							headers: xhr => { // 设置请求头参数（选填）
								xhr.setRequestHeader("Authorization", (this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''))
							},
							response: res => {
								if(res.code=="A0200"||res.code=="A0201"){// 登录过期，重新登录
									this.$store.commit("updateUserInfo", '');
									this.$router.push("/")
									return
								}
								if(res.code=="A0202"){
									this.userLogin()
									return
								}
								return res.data[0]
							},
							size: 2, // 图片不能超过2M
							sizeError: () => {
								this.$message.error('粘贴图片大小不能超过2MB!')
							}
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									QuillWatch.emit(this.quill.id)
								},
								link: function(value) {
									if (value) {
										var href = prompt('请输入链接地址：')
										this.quill.format('link', href)
									} else {
										this.quill.format('link', false)
									}
								},
								video: function(value) {
									if (value) {
										var href = prompt('请输入视频链接：')
										this.quill.format('video', href)
									} else {
										this.quill.format('video', false)
									}
								}
							}
						}
					}
				},
				rules: {
					categoryId: [{required: true,message: '请选择分类', trigger: 'blur'}],
					title: [{required: true,message: '请输入文章标题', trigger: 'blur'}],
					fileListData:[{required: true,message: '请上传图片',validator: fileListDataCheck,trigger: 'blur'}],
					content:[{required: true,message: '文章内容必填',validator: validates,trigger: 'blur'}]
				},
			}
		},
		computed: {
		    userInfo() {
				return this.$store.state.userInfo;
		    },
		},
		watch:{
			userInfo: {
				handler(nval, oval) {
					// console.log(nval, oval);//nval改变后的新数据，oval改变前的旧数据
				},
				deep: false, // 深度监听
				immediate: true,//立即执行
			}
		},
		created() {
			this.getData()
			this.getTags()
			this.importHeaders.Authorization = this.userInfo.token?('Bearer'+' '+ this.userInfo.token):''
			if(this.$route.params.id){
				this.getArticleDetails(this.$route.params.id)
				this.edit = true
			}
		},	
		methods:{
			dialogVisiFlag(val){
				this.dialogVisiblePre = val
			},
			previewItem(item){
				item.nowTime = formatDateOrder(new Date().getTime()/1000)
				this.articleItem = item
				this.dialogVisiblePre = true
			},
			tempSave(){
				if(!this.pushData.title){
					this.$message.error('请填写标题')
					return
				}
				let pushData = this.pushData
				let body = {
					title:pushData.title,
					categoryId:pushData.categoryId,
					content:pushData.content,
					picUrl:this.imgUrl.length>0?this.imgUrl[0]:''
				}
				body.tagIds = this.tagIds
				if(this.edit){
					body.id = pushData.id
				}
				let postUrl = this.edit?articleRedraft:articleDraft
				postUrl(body).then(res=>{
					if(res&&res.success){
						this.$router.replace('/knowledgeClassroom/publicationList')
					}else{
						this.$message.error(res.msg)
					}
				})
			},
			getArticleDetails(id){
				articleGetById({data:{id}}).then(res=>{
					if(res&&res.success){
						let data = res.data
						if(data.picUrl){
							this.fileListData[0] = {url: data.picUrl}
							this.imgUrl[0] = data.picUrl
						}
						delete data.picUrl
						this.$nextTick(() => {
							this.$refs.quillRef.setContents(data.content)
						})
						for(let item of this.tagList){
							if(data.tagIds.includes(item.id)){
								item.isSelected = true
							}
						}
						this.tagIds = data.tagIds
						delete data.tagIds
						this.selectedTags = this.tagList.filter(item=>item.isSelected)
						this.pushData = data
					}
				})
			},
			saveSelected(){
				let selectedTags = this.tagList.filter(item=>item.isSelected)
				if(selectedTags.length>5){
					this.$message.error('最多可以选择五个标签')
					return
				}
				this.selectedTags = JSON.parse(JSON.stringify(selectedTags))
				this.tagIds = []
				if(selectedTags.length>0){
					selectedTags.forEach(item=>{
						this.tagIds.push(item.id) 
					})
				}
				this.dialogVisible1 = false
			},
			toSelectTag(){
				this.dialogVisible1 = true
			},
			getSelected(index,item){
				this.tagList[index].isSelected = !item.isSelected
			},
			saveTags(){
				let data = {
					name:this.tagName,
					enabled:true
				}
				tagPsCreate({data}).then(res=>{
					if(res.success){
						this.getTags()
						this.tagName = ''
					}else{
						this.loading = false
						this.$message.error(res.msg)
					}
				})
			},
			getTags(){
				tagList().then(res=>{
					if(res.success){
						let list = res.data
						list.forEach(item=>{
							item.isSelected = false
						})
						this.tagList = list
					}
				})
			},
			getData(){
				categoryTreeList({data:{enabled:true}}).then(res=>{
					if(res.success){
						let data = res.data
						let arr = []
						if(data.length>0){
							data.forEach(item=>{
								item.label = item.name
								item.value = item.id
								if(item.children&&item.children.length>0){
									for (let i of item.children) {
										i.label = i.name
										i.value = i.id
									}
								}
								arr.push(item)
							})
						}
						this.options = arr
					}
				})
			},
			save(){
				this.$refs.formBox.validate((valid) => {
					if (valid) {
						if(this.imgUrl.length==0){
							this.$message.error('请上传一张图片')
							return
						}
						let pushData = this.pushData
						if(!pushData.content){
							this.$message.error('文章内容不能为空')
							return
						}
						let body = {
							title:pushData.title,
							categoryId:pushData.categoryId,
							content:pushData.content,
							picUrl:this.imgUrl[0]
						}
						body.tagIds = this.tagIds
						if(this.edit){
							body.id = pushData.id
						}
						let postUrl = (this.edit&&pushData.state=='CANCEL_PUBLISHED')?articleRepublish:articlePublish
						postUrl(body).then(res=>{
							if(res&&res.success){
								this.$router.replace('/knowledgeClassroom/publicationList')
							}else{
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			handlePictureCardPreview(file, fileList,types){
				this.imgUrl.forEach((ies,index) =>{
					if(file.response){
						if(ies==file.response.data[0]){
							this.dialogImageUrl = ies
							this.dialogVisible = true
						}
						return
					}
					if(ies==file.url){
						this.dialogImageUrl = ies
						this.dialogVisible = true
					}
				});
			},
			handleRemove(file, fileList,types) {
				this.fileListData.forEach((ies,index) =>{
					if(ies.url){
						if(ies.url==file.url || ies.url==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}else{
						if(ies==file.url || ies==file.response.data[0]){
							this.fileListData.splice(index,1)
						}
					}
				});
				this.imgUrl.forEach((ies,index) =>{
					if(ies==file.url || ies==file.response.data[0]){
						this.imgUrl.splice(index,1)
					}
				});
			},
			uploadSuccess(response, file, fileList,types) {
				let uid = file.uid;
				let idx = fileList.findIndex(item => item.uid === uid)
				if(response.code.indexOf("A02")!=-1){
					fileList.splice(idx, 1)
				}
				if(response.code=="A0200"||response.code=="A0201"){// 登录过期，重新登录
					this.$store.commit("updateUserInfo", '');
					this.$router.push("/")
					return
				}
				if(response.code=="A0202"){
					this.userLogin()
					return
				}
				this.fileListData.push(response.data[0])
				this.imgUrl.push(response.data[0])
			},
			userLogin(){
				let userInfo = this.userInfo
				let postData = {
					"grantType": "PLATFORM_REFRESH_TOKEN",
					refreshTokenTokenRequest:{
						refreshToken: this.userInfo.refreshToken
					}
				}
				userLogin(postData).then(res1=>{
					userInfo.token = res1.data.accessToken;
					userInfo.refreshToken = res1.data.refreshToken;
					this.$store.commit("updateUserInfo", userInfo);
					this.importHeaders.Authorization = userInfo.token?('Bearer'+' '+ userInfo.token):''
				})
			},
			handleExceed(files, fileList) {
				this.$message.warning("当前限制选择 1 个文件");
			},
			beforeAvatarUpload(file) {
				const extension = file.name.endsWith(".jpg")||file.name.endsWith(".JPG");
				const extension1 = file.name.endsWith(".png")||file.name.endsWith(".PNG");
				const extension2 = file.name.endsWith(".jpeg")||file.name.endsWith(".JPEG");
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!extension && !extension1 && !extension2) {
					this.$message({
						message: "上传模板只能是 .jpg,.png,.jpeg 格式!",
						type: "error"
					});
					return false
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2M!");
					return false
				}
				return (extension || extension1 || extension2) && isLt2M;
			},
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.tags{
		display: inline-block;
		border-radius: 6px;
		border: 1px solid #999;
		color: #999;
		padding: 2px 10px;
		box-sizing: border-box;
		margin-right: 20px;
	}
	.activeTag{
		border-color: #409eff;
		color:#409eff;
	}
</style>